import React from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { FuseLayouts, FuseNavigation } from '../@fuse'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const styles = theme => ({
    root: {
        '& .user': {
            '& .username, & .email': {
                transition: theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.shortest,
                    easing: theme.transitions.easing.easeInOut,
                }),
            },
        },
    },
    avatar: {
        width: 72,
        height: 72,
        position: 'absolute',
        top: 92,
        padding: 8,
        background: theme.palette.background.default,
        boxSizing: 'pages-box',
        left: '50%',
        transform: 'translateX(-50%)',
        '& > img': {
            borderRadius: '50%',
        },
    },
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

const mapStateToProps = ({ fuse, authsReducer }) => ({
    navigation: fuse.navigation,
    layoutStyle: fuse.settings.current.layout.style,
    authsReducer: authsReducer,
})

const MainNavbar = ({ classes, navigation, layoutStyle, authsReducer }) => {
    const user = authsReducer.get('token')
    const navigationLayout = FuseLayouts[layoutStyle].type
    return user ? (
        <div className={ classes.root }>
            <FuseNavigation navigation={ navigation } layout={ navigationLayout }/>
        </div>
    ) : null
}

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(MainNavbar),
    ),
)
