export default (theme) => ({
    root: {},
    paper: {
        padding: '20px',
        marginBottom: '30px',
    },
    textField: {
        margin: theme.spacing.unit,
    },
    search: {
        margin: theme.spacing.unit,
        width: 300,
    },
    button: {
        margin: theme.spacing.unit,
        height: 55,
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        width: '800px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
    linkModal: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#e6e3ea',
            transition: 'all 0.2 ease',
        },
    },
    moreInfoRow: {
        margin: '10px 0px',
        fontSize: '16px',
        width: '100%',
    },
})
