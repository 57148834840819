import React from 'react'
import { FuseUtils } from '@fuse/index'
import { Config as Dashboard } from 'pages/dashboard/config'
import { Config as Profile } from 'pages/profile/config'
import { Config as Registrations } from 'pages/Registrations/config'
import Login from 'pages/login'

const routeConfigs = [Dashboard, Profile, Registrations]

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        component: () => <Login />,
    },
]
