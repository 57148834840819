export const navigation = [
    {
        id: 'home',
        title: 'Dashboard',
        type: 'group',
        icon: 'equalizer',
        url: '/',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/',
            },
        ],
    },
    // {
    //     id: 'registrations',
    //     title: 'Cadastros',
    //     type: 'group',
    //     icon: 'equalizer',
    //     url: '/',
    //     children: [
    //         {
    //             id: 'view_registrations',
    //             title: 'Visualizar Cadastros',
    //             type: 'item',
    //             url: '/registrations/view',
    //         },
    //     ],
    // },
]
