import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import * as quickPanelActions from 'components/quickPanel/store/actions'
import { bindActionCreators } from 'redux'

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
    },
    breadcrumb: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        left: '1%',
    },
    breadcrumb_item: {
        marginLeft: '10px',
        marginRight: '10px',
    },
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            toggleQuickPanel: quickPanelActions.toggleQuickPanel,
        },
        dispatch,
    )

class MainToolbar extends React.PureComponent {
    render() {
        const { classes, toggleQuickPanel } = this.props

        return (
            <div className={ classes.root }>
                <Typography style={ { color: '#FFF' } } variant="h5">
                    Dashboard
                </Typography>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(
    connect(
        null,
        mapDispatchToProps,
    )(MainToolbar),
)
