const colorTheme = '#542785'

export default {
    50: '#ECECEE',
    100: '#C5C6CB',
    200: '#9EA1A9',
    300: '#7D818C',
    400: '#5C616F',
    500: colorTheme,
    600: colorTheme,
    700: colorTheme,
    800: colorTheme,
    900: colorTheme,
    A100: '#C5C6CB',
    A200: '#9EA1A9',
    A400: '#5C616F',
    A700: colorTheme,
}
