import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import DashboardContent from './DashboardContent'
import { FuseAnimate, FusePageCarded } from '@fuse'
import BreadCrumb from 'components/BreadCrumb'
import { Home } from '@material-ui/icons'

const styles = (theme) => ({
    layoutRoot: {},
    card: {
        minWidth: 275,
    },
})

const itens = [
    {
        title: 'Dashboard',
        icon: <Home />,
        route: '/',
    },
]

class Index extends React.PureComponent {
    render() {
        return (
            <FusePageCarded
                header={
                    <div className="flex items-center justify-center">
                        <FuseAnimate
                            animation="transition.slideLeftIn"
                            delay={300}
                        >
                            <BreadCrumb itens={itens} separador="/" />
                        </FuseAnimate>
                    </div>
                }
                content={<DashboardContent />}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    authsReducer: state.authsReducer,
})

export default connect(
    mapStateToProps,
    null,
)(withStyles(styles, { withTheme: true })(Index))
