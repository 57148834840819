import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import classNames from 'classnames'

const styles = theme => ({
    root: {},
    textFooter: {
        color: '#DDA416',
    },
})

function MainFooter({ classes }) {
    return (
        <div
            className={ classNames(
                classes.root,
                'flex flex-1 flex-row-reverse items-center px-24',
            ) }
        >
            <Typography
                className={ classNames(classes.textFooter, 'text-right') }
            >
                Powered by{ ' ' }
                <a
                    style={ {
                        color: '#DDA416',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        marginRight: '5px',
                    } }
                    href="https://inovamind.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    InovaMind
                </a>
                - v1.0
            </Typography>
        </div>
    )
}

export default withStyles(styles, { withTheme: true })(MainFooter)
