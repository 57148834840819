import Strapi from 'strapi-sdk-javascript'
import axios from 'axios'
import { toast } from 'react-toastify'

class Service {
    strapi
    tempId
    headers = {
        'x-api-key': 'swXIAPGbLI2LQuHwioxgzaVWxfz0kFuH6Dxy2cHI',
    }

    constructor() {
        this.strapi = new Strapi('https://eudora.api.client.inovamind.com.br')
    }

    handleSuccess = (response) => response.data

    handleError = (error) => {
        // console.log('CODDEEEE', error.message.substr(-3))
        const err = error.message.substr(-3)
        switch (err) {
            case 401:
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                })
                break
            case 404:
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                })
                break
            case 500:
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                })
                break
            default:
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                })
                break
        }
        return Promise.reject(error)
    }

    redirectTo = (document, path) => {
        document.location = path
    }

    login = (identifier, password) =>
        this.strapi.login(identifier, password).then((data) => {
            return data
        })

    get = (collection) =>
        this.strapi.getEntries(`${collection}`).then((data) => {
            console.log(`${collection}`, data)
            return data
        })

    post = (data) =>
        this.strapi.createEntry('registrations', data).then((data) => {
            console.log('post', data)
            this.tempId = data.id
            return data
        })

    put = (data) => this.strapi.updateEntry('registrations', this.tempId, data)

    cpf = (cpf) =>
        axios({
            method: 'GET',
            url: `https://api.inova5.space/search/person/infos?cpf=${cpf.replace(
                /[^\d]+/g,
                '',
            )}`,
            headers: this.headers,
        })

    phone = (cpf) =>
        axios({
            method: 'GET',
            url: `https://api.inova5.space/search/person/phones?cpf=${cpf.replace(
                /[^\d]+/g,
                '',
            )}`,
            headers: this.headers,
        })
}

export default new Service()
