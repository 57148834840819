import * as authsActions from './Auths.actions'
import { toast } from 'react-toastify'
import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import Strapi from 'strapi-sdk-javascript'
import TokenService from './../../services/TokenService'

const strapi = new Strapi('https://eudora.api.client.inovamind.com.br')

/* ************************************ LOGIN ************************************ */

export const authsEpics = (action$, store) => {
    return action$.pipe(
        ofType(authsActions.LOGIN),
        mergeMap((action) => {
            const {
                payload: { identifier, password },
            } = action

            return strapi
                .login(identifier, password)
                .then((data) => {
                    if (data) {
                        toast.success('Bem vindo de volta', {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                    const tokenService = new TokenService()
                    tokenService.setToken(data.jwt)
                    return authsActions.successLogin({
                        ...data.user,
                        token: data.jwt,
                    })
                })
                .catch((err) => {
                    console.log('deu erro', err)
                    toast.error('Email ou senha incorretos!', {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    return authsActions.failLogin()
                })
        }),
    )
}
