import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import MenuAvatar from './MenuAvatar'

import { connect } from 'react-redux'
import * as authActions from '../redux/auths/Auths.actions'

const mapStateToProps = (state) => ({
    clientsReducer: state.clientsReducer,
    authsReducer: state.authsReducer,
})
const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(authActions.logout())
    },
})
const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& .logo-text, & .react-badge': {
            transition: theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
    },
    logo: {},
    logoIcon: {
        width: 150,
        height: 'auto',
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
})

class MainNavbarHeader extends Component {
    logout = () => {
        this.props.logout()
        this.props.history.push({
            pathname: '/',
        })
    }

    render() {
        const load = this.props.authsReducer.get('token')
        const name = this.props.authsReducer.get('firstName')
        return (
            <div className={this.props.classes.root}>
                <div
                    className={classNames(
                        this.props.classes.logo,
                        'flex items-center',
                    )}
                >
                    <img
                        className={classNames(
                            this.props.classes.logoIcon,
                            'logo-icon',
                        )}
                        src="assets/images/eudora_logo_horizontal.png"
                        alt="logo"
                        width="130"
                    />
                </div>
                {load ? (
                    <div
                        style={{
                            position: 'absolute',
                            right: '2%',
                            cursor: 'pointer',
                        }}
                    >
                        <MenuAvatar name={name} logout={this.logout} />
                    </div>
                ) : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withRouter(MainNavbarHeader)))
