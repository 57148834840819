import React from 'react'
import { FuseAnimate, FusePageCarded } from '@fuse'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import RegistrationsContent from './RegistrationsContent'
import BreadCrumb from 'components/BreadCrumb'
import { Home } from '@material-ui/icons'

const itens = [
    {
        title: 'Dashboard',
        icon: <Home />,
        route: '/',
    },
    {
        title: 'Cadastros',
        // icon: <Equalizer />,
        route: '/registrations/view',
    },
]

const mapStateToProps = (state) => ({
    authsReducer: state.authsReducer,
})

class Registrations extends React.Component {
    state = {}

    componentWillMount() {
        const token = this.props.authsReducer.get('token')
        if (token === null) {
            this.props.history.push({
                pathname: '/',
            })
        }
    }

    render() {
        return (
            <FusePageCarded
                header={
                    <div className="flex items-center justify-center">
                        <FuseAnimate
                            animation="transition.slideLeftIn"
                            delay={300}
                        >
                            <BreadCrumb itens={itens} separador="/" />
                        </FuseAnimate>
                    </div>
                }
                content={<RegistrationsContent />}
            />
        )
    }
}

export default connect(
    mapStateToProps,
    null,
)(Registrations)
