import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
    Paper,
    TextField,
    Button,
    InputAdornment,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    LinearProgress,
    Modal,
} from '@material-ui/core'
import { isAbsolute } from 'upath'
import classNames from 'classnames'
import styles from './styles'
import { Search, SaveAlt } from '@material-ui/icons'
import EudoraApi from 'services/EudoraApi'
import moment from 'moment'

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#999',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

class DashboardContent extends React.Component {
    state = {
        search: '',
        registers: null,
        progress: false,
        open: false,
        modalContent: null,
        filter: false,
    }

    componentDidMount() {
        this.getData()
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleOpen = (item) => {
        this.setState({ open: true, modalContent: item })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    getData = async () => {
        try {
            const registers = await EudoraApi.get('registrations')
            await this.setState({
                progress: true,
                registers,
            })
        } catch (error) {
            EudoraApi.handleError(error)
        } finally {
            this.setState({ progress: false })
        }
    }

    firstUpperCase = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1)

    // filterData = async (e) => {
    //     const { search, registers } = this.state
    //     e.preventDefault()
    //     const filteredRegisters = registers.filter((item) => {
    //         return item.cpf.replace(/[^\d]+/g, '') === search
    //     })
    //     await this.setState({
    //         registers: filteredRegisters,
    //         filter: true,
    //     })
    // }

    render() {
        const { classes } = this.props
        const { registers, progress, modalContent, filter } = this.state
        return (
            <React.Fragment>
                <main>
                    <Paper
                        className={classNames(
                            classes.paper,
                            'flex flex-row justify-between items-center',
                        )}
                    >
                        <Typography variant="h4">
                            Visualizar Cadastros Efetuados
                        </Typography>
                    </Paper>
                    <Paper
                        className={classNames(classes.paper, 'flex flex-col')}
                    >
                        {progress ? (
                            <LinearProgress
                                classes={{
                                    colorPrimary: '#542785',
                                    barColorPrimary: '#542785',
                                }}
                            />
                        ) : null}
                        {registers ? (
                            <Table style={{ marginTop: 20 }}>
                                <TableHead>
                                    <TableRow>
                                        <CustomTableCell>Nome</CustomTableCell>
                                        <CustomTableCell>CPF</CustomTableCell>
                                        <CustomTableCell>Email</CustomTableCell>
                                        <CustomTableCell>
                                            Telefone
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            Data de Cadastro
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            % do Cadastro
                                        </CustomTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {registers.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={() =>
                                                this.handleOpen(item)
                                            }
                                            className={classes.linkModal}
                                        >
                                            <TableCell>
                                                {item.name.toUpperCase()}
                                            </TableCell>
                                            <TableCell>{item.cpf}</TableCell>
                                            <TableCell>{item.email}</TableCell>
                                            <TableCell>{item.phone1}</TableCell>
                                            <TableCell>
                                                {moment(item.updatedAt).format(
                                                    'DD/MM/YYYY',
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {item.percent}%
                                                </div>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={item.percent}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : null}
                    </Paper>
                    {modalContent ? (
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.open}
                            onClose={this.handleClose}
                            className={classes.modalContainer}
                        >
                            <div className={classes.modal}>
                                <h2
                                    style={{
                                        marginBottom: '30px',
                                        paddingBottom: '15px',
                                        borderBottom: '1px solid black',
                                    }}
                                >
                                    {modalContent ? modalContent.name : null}
                                </h2>
                                <hr />

                                {modalContent.percent ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong
                                            style={{
                                                display: 'inline-block',
                                                marginRight: 15,
                                            }}
                                        >
                                            % do Cadastro:{' '}
                                        </strong>
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                width: 300,
                                            }}
                                        >
                                            <div>{modalContent.percent}%</div>
                                            <LinearProgress
                                                variant="determinate"
                                                value={modalContent.percent}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                {modalContent.motherName ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>Nome da mãe: </strong>
                                        {modalContent.motherName.toUpperCase()}
                                    </div>
                                ) : null}
                                {modalContent.rg ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>
                                            Documento de Identidade (RG):{' '}
                                        </strong>
                                        {modalContent.rg}
                                    </div>
                                ) : null}
                                {modalContent.phone1 ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>Telefone 1: </strong>
                                        {modalContent.phone1}
                                    </div>
                                ) : null}
                                {modalContent.phone2 ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>Telefone 2: </strong>
                                        {modalContent.phone2}
                                    </div>
                                ) : null}
                                {modalContent.phone3 ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>Telefone 3: </strong>
                                        {modalContent.phone3}
                                    </div>
                                ) : null}
                                {modalContent.matrial_status ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>Estado Civil: </strong>
                                        {this.firstUpperCase(
                                            modalContent.matrial_status,
                                        )}
                                    </div>
                                ) : null}
                                {modalContent.spouseData === 'yes' ? (
                                    <React.Fragment>
                                        <div className={classes.moreInfoRow}>
                                            <strong>Nome do Conjuge: </strong>
                                            {modalContent.spouse_name}
                                        </div>
                                        <div className={classes.moreInfoRow}>
                                            <strong>Email do Conjuge: </strong>
                                            {modalContent.spouse_email}
                                        </div>
                                        <div className={classes.moreInfoRow}>
                                            <strong>
                                                Telefone do Conjuge:{' '}
                                            </strong>
                                            {modalContent.spouse_phone}
                                        </div>
                                    </React.Fragment>
                                ) : null}
                                {modalContent.address_place ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>Endereço: </strong>
                                        {modalContent.address_place},{' '}
                                        {modalContent.address_district}
                                    </div>
                                ) : null}
                                {modalContent.address_complement ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>Complemento: </strong>
                                        {modalContent.address_complement}
                                    </div>
                                ) : null}
                                {modalContent.address_city ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>Cidade: </strong>
                                        {modalContent.address_city}
                                    </div>
                                ) : null}
                                {modalContent.address_country ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>País: </strong>
                                        {modalContent.address_country}
                                    </div>
                                ) : null}
                                {modalContent.address_zipcode ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>CEP: </strong>
                                        {modalContent.address_zipcode}
                                    </div>
                                ) : null}
                                {modalContent.updatedAt ? (
                                    <div className={classes.moreInfoRow}>
                                        <strong>Data de Cadastro: </strong>
                                        {moment(modalContent.updatedAt).format(
                                            'DD/MM/YYYY, h:mm:ss',
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </Modal>
                    ) : null}
                </main>
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(DashboardContent)
