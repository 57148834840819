import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FuseAnimate, FusePageSimple } from '@fuse'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { login } from 'redux/auths/Auths.actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit *
            3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
})

const mapStateToProps = (state) => ({
    authsReducer: state.authsReducer,
})
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            login,
        },
        dispatch,
    )

class Login extends Component {
    state = {
        form: {
            identifier: '',
            password: '',
        },
        open: false,
        redirect: false,
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.authsReducer.get('token') !==
            nextProps.authsReducer.get('token')
        )
    }

    componentDidUpdate() {
        this.navigateToDashboard()
    }

    navigateToDashboard = () => {
        const token = this.props.authsReducer.get('token')

        if (token !== null) {
            this.props.history.push({
                pathname: '/dashboard',
            })
        }
    }

    componentWillMount() {
        this.navigateToDashboard()
    }

    submit = (e) => {
        e.preventDefault()
        const { identifier, password } = this.state.form

        this.props.login(identifier, password)
    }

    handleChange = (fieldname) => (event) => {
        const form = {
            ...this.state.form,
        }
        form[fieldname] = event.target.value
        this.setState({ form })
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            const { identifier, password } = this.state.form
            this.props.login(identifier, password)
            this.setState({ redirect: true })
        }
    }

    render() {
        const { classes } = this.props
        const { identifier, password } = this.state
        console.log('authsReducer', this.props.authsReducer.get('load'))

        const load = this.props.authsReducer.get('load')

        return (
            <FusePageSimple
                content={
                    <React.Fragment>
                        <CssBaseline />
                        <main className={classes.layout}>
                            <FuseAnimate
                                animation="transition.fadeIn"
                                duration={2000}
                            >
                                <Paper className={classes.paper}>
                                    <div className="logo">
                                        <img
                                            style={{
                                                width: 150,
                                                margin: '20px 0px',
                                            }}
                                            src="assets/images/eudora_logo_positive.png"
                                            alt="logo"
                                        />
                                    </div>
                                    {/* <Typography component="h1" variant="h5">
                                        Inovamind
                                    </Typography> */}
                                    <form className={classes.form}>
                                        <FormControl
                                            margin="normal"
                                            required
                                            fullWidth
                                        >
                                            <InputLabel htmlFor="identifier">
                                                Usuário
                                            </InputLabel>
                                            <Input
                                                value={identifier}
                                                onChange={this.handleChange(
                                                    'identifier',
                                                )}
                                                id="identifier"
                                                name="identifier"
                                                autoComplete="identifier"
                                                autoFocus
                                            />
                                        </FormControl>
                                        <FormControl
                                            margin="normal"
                                            required
                                            fullWidth
                                        >
                                            <InputLabel htmlFor="password">
                                                Senha
                                            </InputLabel>
                                            <Input
                                                value={password}
                                                onChange={this.handleChange(
                                                    'password',
                                                )}
                                                name="password"
                                                type="password"
                                                id="password"
                                                autoComplete="current-password"
                                                onKeyDown={this.onEnterPress}
                                            />
                                        </FormControl>
                                        {load ? (
                                            <div
                                                style={{ textAlign: 'center' }}
                                            >
                                                <CircularProgress />
                                            </div>
                                        ) : null}

                                        <Button
                                            onClick={this.submit}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            Entrar
                                        </Button>
                                    </form>
                                </Paper>
                            </FuseAnimate>
                        </main>
                    </React.Fragment>
                }
            />
        )
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(
    withStyles(styles, { name: 'Cart' }),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Login)
